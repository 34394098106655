//require('./bootstrap');
window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');

import $ from 'jquery';

window.$ = window.jQuery = $;

import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

$(document).ready(function () {
    const navLinks = document.querySelectorAll('.nav-item-mv')
    navLinks.forEach((l) => {
        l.addEventListener('click', () => {
            $('#burger-menu-wrapper > label').click();
        })
    })

    const swiper = new Swiper(".swiper", {
        // Optional parameters
        direction: "horizontal",
        loop: true,
        autoHeight: false,
        centeredSlides: true,
        slidesPerView: 1,
        // Responsive breakpoints
        breakpoints: {
            0: {
                slidesPerView: 1,
                spaceBetween: 100,
            },
            640: {
                slidesPerView: 2,
                spaceBetween: 100,
            },
            1100: {
                slidesPerView: 3,
                spaceBetween: 100,
            }
        },

        // If we need pagination
        pagination: {
            el: ".swiper-pagination"
        },

        // Navigation arrows
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
        }

        // And if we need scrollbar
        /*scrollbar: {
          el: '.swiper-scrollbar',
        },*/
    });

    $('#contact-form').submit(function (e) {
        const tos = document.getElementById('tos');
        const privacy = document.getElementById('privacy');
        if(!tos.checked || !privacy.checked) {
            // Respond to the result
            $('#tosError').show();
            e.preventDefault();
        }
    });
    $('#particular-form').submit(function (e) {
        e.preventDefault();
        const tos = document.getElementById('particular-tos');
        const privacy = document.getElementById('particular-privacy');
        $('#tosError').hide();
        if(!tos.checked || !privacy.checked) {
            // Respond to the result
            $('#tosError').show();
            e.preventDefault();
        } else {
            $("#errors-wrapper").hide().empty();
            $.ajax({
                type: $(this).attr('method'),
                url: $(this).attr('action'),
                data: $(this).serialize(),
                dataType: "json",
                encode: true,
            }).done(function (data) {
                return window.location.href = data.url;
            }).fail(function ( message, error) {
                for (const key in message.responseJSON.errors) {
                    $('#particular-form .'+key).addClass('is-invalid');
                }
                let errorList = '<ul>';
                Object.values(message.responseJSON.errors).forEach(error => {
                    errorList += '<li>'+error+'</li>';
                });
                errorList += '</ul>';
                $("#errors-wrapper").show().append(errorList);
            });
        }
    });
    $('#company-form').submit(function (e) {
        e.preventDefault();
        const tos = document.getElementById('company-tos');
        const privacy = document.getElementById('company-privacy');
        $('#tosError').hide();
        if(!tos.checked || !privacy.checked) {
            // Respond to the result
            $('#tosError').show();
            e.preventDefault();
        } else {
            $("#errors-wrapper").hide().empty();
            $.ajax({
                type: $(this).attr('method'),
                url: $(this).attr('action'),
                data: $(this).serialize(),
                dataType: "json",
                encode: true,
            }).done(function (data) {
                return window.location.href = data.url;
            }).fail(function ( message, error) {
                let errorList = '<ul>';
                Object.values(message.responseJSON.errors).forEach(error => {
                    errorList += '<li>'+error+'</li>';
                });
                errorList += '</ul>';
                $("#errors-wrapper").show().append(errorList);
            });
        }
    });
    $("#particular-form input, #company-form input, #contact-form input, #contact-form textarea").keyup(function(e) {
        if($(this).val() != ''){
            $(this).parent().find('.input-label').show();
        } else {
            $(this).parent().find('.input-label').hide();
        }
    });
    $("#particular-btn").click(function() {
        if($(this).is(":checked")) {
            $("#company").hide();
            $("#particular").show();
        }
    });
    $("#company-btn").click(function() {
        if($(this).is(":checked")) {
            $("#particular").hide();
            $("#company").show();
        }
    });

});
let $item = $('#header-carousel .carousel-item');
let $wHeight = $(window).height();
$item.eq(0).addClass('active');
$item.height($wHeight);
$item.addClass('full-screen');

$('#header-carousel img').each(function() {
    let $src = $(this).attr('src');
    let $color = $(this).attr('data-color');
    $(this).parent().css({
        'background-image' : 'url(' + $src + ')',
        'background-color' : $color
    });
    $(this).remove();
});

window.addEventListener('scroll', function () {
    if (window.scrollY < $('#header-carousel').height() - 120) {
        $('.home-navbar').removeClass('light').addClass('dark')
        $('.home-navbar .navbar-brand img').attr('src', '/img/logo.svg')
    } else {
        $('.home-navbar').removeClass('dark').addClass('light')
        $('.home-navbar .navbar-brand img').attr('src', '/img/logo-color.svg')
    }
});

window.addEventListener('resize', function (){
    $wHeight = $(window).height();
    $item.height($wHeight);
});
